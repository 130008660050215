import React from "react";
import RichText from "../../../shared/components/atoms/rich-text/rich-text";
import iconoAbajo from '../../../assets/images/sustainability/flechaAbajo.svg'
import bienestarPersonas from '../../../assets/images/sustainability/bienestar_personas.svg'
import bienestarPlaneta from '../../../assets/images/sustainability/bienestar_planeta.svg'
import useMobileMode from "../../../shared/hooks/useMobileMode";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import './sustainabilityPillarsContent.scss'

const SustainabilityPillars = props => {
    const { pillarsInfo } = props
    const { pilar1, pilar2 } = pillarsInfo
    const { isMobile } = useMobileMode()

    return (
        <div className='f-pillars-content'>
            <h2 className="f-pillars-content-title">{pillarsInfo.principalTitle}</h2>
            <RichText className='f-pillars-content-description' jsonInfo={pillarsInfo.principalDescription.json} />
            {!isMobile ?
                <div className="f-pillars-content-desktop">
                    <div className="f-pillars-content-desktop-card1">
                        <h3 className="f-pillars-content-desktop-card1-title">{pilar1.title}</h3>
                            <img className='f-pillars-content-desktop-card1-image'
                            src={bienestarPersonas}
                            alt='Icono manos' />
                        <p className="f-pillars-content-desktop-card1-description">{documentToReactComponents(pilar1.description.json)}</p>
                        <a className="f-pillars-content-desktop-button" href={pilar1.redireccion}>Ver acciones</a>
                    </div>

                    <div className="f-pillars-content-desktop-card2">
                        <h3 className="f-pillars-content-desktop-card2-title">{pilar2.title}</h3>
                            <img className='f-pillars-content-desktop-card2-image'
                            src={bienestarPlaneta}
                            alt='Icono manos' />
                        <p className="f-pillars-content-desktop-card2-description">{documentToReactComponents(pilar2.description.json)}</p>
                        <a className="f-pillars-content-desktop-button" href={pilar2.redireccion}>Ver acciones</a>
                    </div>
                </div>
                :
                <div className="f-pillars-content-mobile">
                    <a className="f-pillars-content-mobile-card1" href={pilar1.redireccion}>
                        <img
                            className='f-pillars-content-mobile-card1-image'
                            src={bienestarPersonas}
                            alt='Icono manos'
                        />
                        <h3 className="f-pillars-content-mobile-card1-title">{pilar1.title}</h3>
                        <img
                            className='f-pillars-content-mobile-card1-down'
                            src={iconoAbajo}
                            alt='card Tena pillars'
                        />
                    </a>

                    <a className="f-pillars-content-mobile-card2" href={pilar2.redireccion}>
                        <img
                            className='f-pillars-content-mobile-card2-image'
                            src={bienestarPlaneta}
                            alt='Icono balanza'
                        />
                        <h3 className="f-pillars-content-mobile-card2-title">{pilar2.title}</h3>
                        <img
                            className='f-pillars-content-mobile-card2-down'
                            src={iconoAbajo}
                            alt='card Tena pillars'
                        />
                    </a>
                </div>}
        </div>
    )
}
export default SustainabilityPillars