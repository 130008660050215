import React from "react";
import RichText from "../../../shared/components/atoms/rich-text/rich-text";
import Img from 'gatsby-image'
import './sectionBrandInformation.scss'

const BrandInformation = props => {
    const { brandInfo } = props

    return (
        <div className='f-brand-content'>
            <h2 className="f-brand-content-title">{brandInfo.title}</h2>
            <div className="f-brand-content-container">
                {brandInfo.tieneVideo ?
                    <iframe
                        className='f-brand-content-video'
                        frameBorder='0'
                        sandbox='allow-popups allow-same-origin allow-scripts'
                        scrolling='no'
                        src={
                            brandInfo.video.includes('youtube')
                                ? brandInfo.video.replace('watch?v=', 'embed/')
                                : brandInfo.video.includes('youtu.be')
                                    ? brandInfo.video.replace('.be/', 'be.com/embed/')
                                    : brandInfo.video
                        }
                        allowFullScreen
                    /> :
                    <Img
                        className='f-brand-content-img'
                        fluid={brandInfo.imagen.fluid}
                        alt={brandInfo.imagen.description}
                    />
                }
                <RichText className='f-brand-content-description' jsonInfo={brandInfo.description.json} />
            </div>
        </div>
    )
}
export default BrandInformation