import React, { useState } from "react"
import BannerLandingSustainability from '../../molecules/bannerLandingSustainability/bannerLandingSustainability'
import BrandInformation from "../../molecules/sectionBrandInformation/sectionBrandInformation"
import SustainabilityPillars from "../../molecules/sustainabilityPillars/sustainabilityPillarsContent"
import TenaProtectsContent from "../../molecules/tenaProtectsContent/tenaProtectsContent"
import Slider from "react-slick"
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Img from 'gatsby-image'
import useMobileMode from '../../../shared/hooks/useMobileMode'
import './landing-sustainability-content.scss'

const LandingSustainabilityContent = props => {
    const [active, setactive] = useState(0)
    const { data } = props
    const bannerInfo = {
        images: data.banner[0],
        titulo: data.textoBanner
    }
    const brandInfo = {
        title: data.tituloDescripcionMarca,
        video: data.urlVideo,
        tieneVideo: data.tieneVideo,
        imagen: data.imagenMarca,
        description: data.descripcionMarca
    }
    const tenaProtects = {
        title: data.tituloTenaProtects,
        image: data.imagenTenaProtects,
        description: data.textoTenaProtect,
    }
    const pillarsInfo = {
        principalTitle: data.tituloPilares,
        principalDescription: data.textoPrincipalPilares,
        pilar1: {
            title: data.pilares[0].titulo,
            description: data.pilares[0].descripcionRetail,
            redireccion: data.pilares[0].redireccionRetail
        },
        pilar2: {
            title: data.pilares[1].titulo,
            description: data.pilares[1].descripcionRetail,
            redireccion: data.pilares[1].redireccionRetail
        }
    }
    const { isMobile } = useMobileMode()
    const ancla = (option) => {
        const element = document.getElementById(option);
        window.scrollTo({
            top: element?.offsetTop - (isMobile ? 90 : 200),
            behavior: "smooth",
        });
    }

    const NextArrow = props => {
        const { onClick } = props
        return <span className="f-slider-home-bannerppl-next" onClick={onClick} />
    }
    const PrevArrow = props => {
        const { onClick } = props
        return <span className="f-slider-home-bannerppl-prev" onClick={onClick} />
    }
    const sliderConf = {
        infinite: true,
        slidesToShow: isMobile ? 1 : 2,
        slidesToScroll: 1,
        speed: 1000,
        autoplaySpeed: 6000,
        autoplay: false,
        pauseOnHover: true,
        arrows: true,
        dots: false,
        centerPadding: '160px',
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    }
    return (
        <section className="f-landing-sustainability">
            <div className="f-landing-sustainability-banner">
                <BannerLandingSustainability bannerInfo={bannerInfo} isMobile={isMobile} />
            </div>
            <section className="f-landing-sustainability-menu">
                {data.menuAncla.map((option, index) => {
                    return (<>
                        <div className={`${active === index ? "f-landing-sustainability-menu-group" : ''}`}>
                            <div
                                key={index}
                                onClick={() => { ancla(index); setactive(index) }}
                                className={`f-landing-sustainability-menu-button ${active === index ? "f-landing-sustainability-menu-button--active" : ""}`}
                            >
                                {option.titulo}

                            </div>
                            {!isMobile && active === index ?
                                <div className="f-landing-sustainability-menu-button--active-line">

                                </div> : null}
                        </div>
                    </>

                    )
                })}
            </section>
            <section id={0} className="f-landing-sustainability-brand">
                <BrandInformation brandInfo={brandInfo} />
            </section>
            <section id={1} className="f-landing-sustainability-pilares">
                <SustainabilityPillars pillarsInfo={pillarsInfo} />
            </section>
            <section id={2} className="f-landing-sustainability-protect">
                <TenaProtectsContent tenaProtects={tenaProtects} />
            </section>
            <section id={3} className='f-landing-sustainability-products'>
                <h2 className="f-landing-sustainability-products-title">{data.tituloSlider}</h2>

                <Slider {...sliderConf} className='f-landing-sustainability-products-slider'>
                    {data.imagenesSlider.map((producto, index) => {
                        return (
                            <div className="f-landing-sustainability-products-slider-content" href="" key={index}>
                                <Img className="f-landing-sustainability-products-slider-content-image" fluid={isMobile ? producto.bannerMobile.fluid : producto.bannerDesktop.fluid}
                                    alt={isMobile ? producto.bannerMobile.description : producto.bannerDesktop.description} />
                                <a href={producto.enlace} target='_blank' rel="noreferrer" className="f-landing-sustainability-products-slider-content-button">Ver producto</a>
                            </div>
                        )
                    })}
                </Slider>
            </section>
        </section>
    )
}
export default LandingSustainabilityContent