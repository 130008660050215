// import { graphql } from 'gatsby'
import React from 'react'
import LandingSustainabilityContent from '../components/organisms/landing-sustainability-content/landing-sustainability-content'
import Layout from '../shared/components/organisms/layout/layout'
import SEO from '../shared/components/seo'
import useMobileMode from '../shared/hooks/useMobileMode'

const LandingSustainability = props => {
    const { isMobile, currentPage } = useMobileMode()
    const dataFiltered = props.pageContext

    return (
        <Layout isMobile={isMobile} currentPage={currentPage}>
            <SEO
                title={props.pageContext.metaTitulo|| 'prueba'}
                description={props.pageContext.metaDescripcion.metaDescripcion}
                image={dataFiltered.banner[0]?.bannerDesktop?.fluid?.src}
                slug={'/' + dataFiltered}
                lang={props.pageContext.language}

            />
            <LandingSustainabilityContent
                isMobile={isMobile}
                data={dataFiltered}
            />
        </Layout>

    )
}

export default LandingSustainability