import React from "react";
import Img from 'gatsby-image'
import RichText from "../../../shared/components/atoms/rich-text/rich-text";
import './tenaProtectsContent.scss'

const TenaProtectsContent = props => {
    const { tenaProtects } = props
    return (
        <div className='f-protects-content'>
            <RichText className="f-protects-content-title" jsonInfo={tenaProtects.title.json}/>
            <div className="f-protects-content-container">
                <Img className='f-protects-content-image'
				fluid={tenaProtects.image.fluid}
				alt='imagen Tena Protects'/>
                <RichText className='f-protects-content-description' jsonInfo={tenaProtects.description.json} />
            </div>
        </div>
    )
}
export default TenaProtectsContent