import React from "react";
import Img from 'gatsby-image'
import './bannerLandingSustainability.scss'

const BannerLandingSustainability = props => {
    const imgDesktop = props.bannerInfo.images.bannerDesktop
	const imgMobile = props.bannerInfo.images.bannerMobile
    const tituloBanner= props.bannerInfo.titulo

    return (
        <div className='f-banner-landing'>
			<Img className='f-banner-landing-img'
				fluid={props.isMobile ? imgMobile.fluid : imgDesktop.fluid}
				alt={imgDesktop.description}
			/>
			<h1 className="f-banner-landing-title">{tituloBanner}</h1>
		</div>
    )
}
export default BannerLandingSustainability